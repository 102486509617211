import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  LocationOn,
  Sync as SyncIcon,
  Verified,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Divider,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'

import chooseLifeMap from '~/assets/choose_life_map.png'
import EconomicQuestionIcon from '~/components/EconomicQuestionIcon'
import ScreenHeader from '~/components/ScreenHeader'
import MainLayout from '~/components/layouts/MainLayout'
import { queries } from '~/queries'
import { useLocalizedCountries } from '~/utils/hooks/useLocalizedCountries'
import { usePermissions } from '~/utils/hooks/usePermissions'
import { useRole } from '~/utils/hooks/useRole'
import { getLanguageByCode } from '~/utils/lang-utils'
import { PERMISSIONS } from '~/utils/permissions-utils'
import { ROLES } from '~/utils/role-utils'
import { type EconomicLibraryQuestion } from '~/utils/types/economic-library'
import { type PlatformLanguage } from '~/utils/types/i18n'

import EconomicLibraryDeleteModal from './economics/EconomicLibraryDeleteModal'
import EconomicLibraryDetailsModal from './economics/EconomicLibraryDetailsModal'
import EconomicLibraryFilters, {
  type EconomicLibraryFiltersShape,
} from './economics/EconomicLibraryFilters'
import EconomicLibraryForm from './economics/EconomicLibraryForm'

enum ModalType {
  VIEW,
  CREATE,
  DELETE,
}

export default function EconomicLibrary() {
  const { t } = useTranslation()

  const [filters, setFilters] = useState<EconomicLibraryFiltersShape>({
    page: 0,
    status: '',
    filter: '',
    country: null,
    language: null,
    verified: false,
    stoplightType: null,
    measurementUnit: null,
  })

  const [openModal, setOpenModal] = useState<ModalType | null>(null)
  const [selectedQuestion, setSelectedQuestion] =
    useState<EconomicLibraryQuestion | null>(null)

  const queryClient = useQueryClient()
  const economicQuestionsQuery = useInfiniteQuery({
    ...queries.libraries.getListLibraryQuestions({
      filter: filters.filter,
      status: filters.status,
      verified: filters.verified,
      country: filters.country?.code ?? '',
      language: filters.language?.code ?? '',
      stoplightType: filters.stoplightType?.code ?? '',
      measurementUnit: filters.measurementUnit?.code ?? '',
    }),

    getNextPageParam: (lastPage, pages) => {
      if (lastPage.totalPages === 0) return null
      return lastPage.totalPages === pages.length ? null : pages.length
    },
    select: data => ({
      ...data,
      pages: data.pages.flatMap(page => page.content),
    }),
  })

  return (
    <MainLayout>
      <Container maxWidth="xl">
        <EconomicLibraryForm
          key={selectedQuestion?.id}
          question={selectedQuestion}
          isOpen={openModal === ModalType.CREATE}
          onClose={({ didSubmit }) => {
            setOpenModal(null)
            setSelectedQuestion(null)
            if (didSubmit) {
              void queryClient.resetQueries({
                queryKey: queries.libraries._def,
              })
            }
          }}
        />

        <EconomicLibraryDetailsModal
          open={openModal === ModalType.VIEW}
          question={selectedQuestion}
          onClose={() => {
            setOpenModal(null)
            setSelectedQuestion(null)
          }}
        />

        <EconomicLibraryDeleteModal
          open={openModal === ModalType.DELETE}
          question={selectedQuestion}
          toggleModal={() => {
            setOpenModal(null)
            setSelectedQuestion(null)
          }}
          afterSubmit={didSubmit => {
            setOpenModal(null)
            if (didSubmit) {
              void economicQuestionsQuery.refetch()
            }
          }}
        />

        <ScreenHeader
          title={t('views.toolbar.economics')}
          image={chooseLifeMap}
        />

        <EconomicLibraryFilters
          filterInput={filters}
          setFilterInput={newFilter => {
            setFilters(prev => ({ ...prev, ...newFilter }))
          }}
          handleCreate={() => {
            setSelectedQuestion(null)
            setOpenModal(ModalType.CREATE)
          }}
        />

        <Grid2 container spacing={2}>
          {economicQuestionsQuery.isLoading &&
            Array.from({ length: 12 }).map((_, i) => (
              <Grid2 xs={12} sm={6} md={4} xl={3} key={i}>
                <Card sx={{ height: '100%' }} variant="outlined">
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    sx={{ minHeight: 300 }}
                  />
                </Card>
              </Grid2>
            ))}

          {economicQuestionsQuery.isSuccess &&
            economicQuestionsQuery.data.pages.length === 0 && (
              <Grid2 xs={12}>
                <Alert severity="info" sx={{ alignItems: 'center' }}>
                  {t('views.report.table.noRecords')}
                </Alert>
              </Grid2>
            )}

          {economicQuestionsQuery.isSuccess &&
            economicQuestionsQuery.data.pages.map(question => (
              <Grid2 key={question.id} xs={12} sm={6} md={4} xl={3}>
                <EconomicLibraryCard
                  question={question}
                  handleViewQuestion={question => {
                    setSelectedQuestion(question)
                    setOpenModal(ModalType.VIEW)
                  }}
                  handleDeleteQuestion={question => {
                    setSelectedQuestion(question)
                    setOpenModal(ModalType.DELETE)
                  }}
                  handleEditQuestion={question => {
                    setSelectedQuestion(question)
                    setOpenModal(ModalType.CREATE)
                  }}
                />
              </Grid2>
            ))}

          {economicQuestionsQuery.isError && (
            <Grid2 xs={12}>
              <Alert
                severity="error"
                sx={{ alignItems: 'center' }}
                action={
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => {
                      void economicQuestionsQuery.refetch()
                    }}
                  >
                    {t('general.reload')}
                  </Button>
                }
              >
                {t('general.error')}
              </Alert>
            </Grid2>
          )}
        </Grid2>

        {economicQuestionsQuery.hasNextPage && (
          <Stack
            my={2}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <LoadingButton
              color="primary"
              variant="outlined"
              endIcon={<SyncIcon />}
              loading={economicQuestionsQuery.isFetchingNextPage}
              onClick={() => {
                void economicQuestionsQuery.fetchNextPage()
              }}
            >
              {t('general.showMore')}
            </LoadingButton>
          </Stack>
        )}
      </Container>
    </MainLayout>
  )
}

interface EconomicLibraryCardProps {
  question: EconomicLibraryQuestion
  handleViewQuestion: (question: EconomicLibraryQuestion) => void
  handleEditQuestion: (question: EconomicLibraryQuestion) => void
  handleDeleteQuestion: (question: EconomicLibraryQuestion) => void
}

function EconomicLibraryCard({
  question,
  handleViewQuestion,
  handleDeleteQuestion,
  handleEditQuestion,
}: EconomicLibraryCardProps) {
  const { t, i18n } = useTranslation()

  const { userHasAnyRole } = useRole()
  const { userHasAnyPermissions } = usePermissions()

  const lang = getLanguageByCode(i18n.language as PlatformLanguage)
  const countryOptions = useLocalizedCountries(lang)

  const countryByCode = useMemo(() => {
    const country = countryOptions.find(
      country => country.code === question.country,
    )
    return country?.label ?? question.country
  }, [countryOptions, question.country])

  const canShowDeleteButton =
    userHasAnyPermissions([PERMISSIONS.ECONOMICS_DELETE]) ||
    userHasAnyRole([ROLES.ADMIN])

  const canShowCreateButton =
    userHasAnyPermissions([PERMISSIONS.ECONOMICS_WRITE]) ||
    userHasAnyRole([ROLES.ADMIN, ROLES.PS_TEAM])

  return (
    <Card
      variant="outlined"
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardActionArea
        sx={{ height: '100%' }}
        onClick={() => {
          handleViewQuestion(question)
        }}
      >
        <CardHeader
          title={countryByCode || t('economicLibrary.noCountry')}
          titleTypographyProps={{
            color: theme => theme.palette.grey[700],
            fontSize: theme => theme.typography.subtitle1.fontSize,
          }}
          avatar={
            <Avatar
              sx={{
                bgcolor: theme => theme.palette.primary.main,
                display: { xs: 'none', lg: 'flex' },
              }}
            >
              <LocationOn />
            </Avatar>
          }
          subheader={
            <Chip
              size="small"
              color="primary"
              variant="outlined"
              label={t(`answerType.${question.answerType}`)}
            />
          }
          action={
            <Stack direction="row" alignItems="center" spacing={1}>
              {question.verified && (
                <Tooltip title={t('views.indicator.create.verified')}>
                  <Verified color="primary" />
                </Tooltip>
              )}

              {question.verified && <Divider orientation="vertical" flexItem />}

              <Tooltip title={t(`answerType.${question.answerType}`)} arrow>
                <span>
                  <EconomicQuestionIcon type={question.answerType} />
                </span>
              </Tooltip>
            </Stack>
          }
        />
        <CardContent
          sx={{
            gap: 2,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="body2"
            textAlign="center"
            fontWeight="bold"
            color="primary"
          >
            {question.shortName}
          </Typography>

          <Typography
            textAlign="justify"
            sx={{ fontSize: 14, color: theme => theme.palette.grey[700] }}
          >
            {question.questionText}
          </Typography>
        </CardContent>
      </CardActionArea>

      <Divider />

      <CardActions sx={{ justifyContent: 'space-around' }}>
        {canShowDeleteButton && (
          <Button
            color="error"
            variant="outlined"
            startIcon={<DeleteIcon />}
            fullWidth
            onClick={() => {
              handleDeleteQuestion(question)
            }}
          >
            {t('general.delete')}
          </Button>
        )}
        {canShowCreateButton && (
          <Button
            fullWidth
            variant="outlined"
            startIcon={<EditIcon />}
            onClick={() => {
              handleEditQuestion(question)
            }}
          >
            {t('general.edit')}
          </Button>
        )}
      </CardActions>
    </Card>
  )
}
