import { Add as AddIcon, Verified as VerifiedIcon } from '@mui/icons-material'
import {
  Autocomplete,
  type AutocompleteRenderInputParams,
  Button,
  CircularProgress,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  TextField,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { useQuery } from '@tanstack/react-query'

import SearchInput from '~/components/inputs/SearchInput'
import { queries } from '~/queries'
import { useLocalizedCountries } from '~/utils/hooks/useLocalizedCountries'
import { usePermissions } from '~/utils/hooks/usePermissions'
import { useRole } from '~/utils/hooks/useRole'
import { getLanguageByCode } from '~/utils/lang-utils'
import { PERMISSIONS } from '~/utils/permissions-utils'
import { ROLES } from '~/utils/role-utils'
import { type PlatformLanguage } from '~/utils/types/i18n'

export interface EconomicLibraryFiltersShape {
  page: number
  status: string
  filter: string
  verified: boolean
  country: { code: string; label: string } | null
  language: { code: string; description: string } | null
  stoplightType: { code: string; description: string } | null
  measurementUnit: { code: string; description: string } | null
}

interface EconomicLibraryFiltersProps {
  filterInput: EconomicLibraryFiltersShape
  handleCreate: () => void
  setFilterInput: (newFilters: EconomicLibraryFiltersShape) => void
}

export default function EconomicLibraryFilters({
  filterInput,
  handleCreate,
  setFilterInput,
}: EconomicLibraryFiltersProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { userHasAnyRole } = useRole()
  const { userHasAnyPermissions } = usePermissions()

  const platformLanguage = language as PlatformLanguage

  const showManagementFeatures =
    userHasAnyPermissions([PERMISSIONS.ECONOMICS_WRITE]) ||
    userHasAnyRole([ROLES.ADMIN, ROLES.PS_TEAM])

  const languagesQuery = useQuery({
    ...queries.languages.supportedLanguages(platformLanguage),
    staleTime: Infinity,
    select: data => data.data.data.supportedLanguages,
  })

  const libraryFilterOptionsQuery = useQuery({
    ...queries.libraries.getLibraryFilterOptions(platformLanguage),
    staleTime: Infinity,
    select: data => data.data.data.listLibraryOptions,
  })

  const lang = getLanguageByCode(language)
  const countryOptions = useLocalizedCountries(lang)

  return (
    <Grid2 container spacing={2} alignItems="center">
      <Grid2 xs={12} sm={6} md={4}>
        <SearchInput
          value={filterInput.filter}
          onChange={newValue => {
            setFilterInput({ ...filterInput, filter: newValue })
          }}
        />
      </Grid2>

      <Grid2 xs={12} sm={6} md={4}>
        <Autocomplete
          autoHighlight
          value={filterInput.language}
          loading={languagesQuery.isLoading}
          options={languagesQuery.data ?? []}
          getOptionLabel={option => option.description}
          renderInput={params => (
            <LoadingTextInput
              params={params}
              label={t('views.languageFilter.label')}
              isLoading={languagesQuery.isLoading}
            />
          )}
          onChange={(_event, value) => {
            setFilterInput({ ...filterInput, language: value })
          }}
        />
      </Grid2>

      <Grid2 xs={12} sm={6} md={4}>
        <Autocomplete
          autoHighlight
          options={countryOptions}
          value={filterInput.country}
          getOptionLabel={option => option.label}
          renderInput={params => (
            <LoadingTextInput
              params={params}
              label={t('views.solutions.form.country')}
            />
          )}
          onChange={(_event, value) => {
            setFilterInput({ ...filterInput, country: value })
          }}
        />
      </Grid2>

      <Grid2 xs={12} sm={6} md={4}>
        <Autocomplete
          autoHighlight
          loading={libraryFilterOptionsQuery.isLoading}
          options={libraryFilterOptionsQuery.data?.measurementUnits ?? []}
          value={filterInput.measurementUnit}
          getOptionLabel={option => option.description}
          renderInput={params => (
            <LoadingTextInput
              params={params}
              label={t('views.indicator.filter.measurement')}
              isLoading={libraryFilterOptionsQuery.isLoading}
            />
          )}
          onChange={(_event, value) => {
            setFilterInput({ ...filterInput, measurementUnit: value })
          }}
        />
      </Grid2>

      <Grid2 xs={12} sm={6} md={4}>
        <Autocomplete
          autoHighlight
          loading={libraryFilterOptionsQuery.isLoading}
          options={libraryFilterOptionsQuery.data?.stoplights ?? []}
          value={filterInput.stoplightType}
          getOptionLabel={option => option.description}
          renderInput={params => (
            <LoadingTextInput
              params={params}
              label={t('views.indicator.filter.stoplights')}
              isLoading={libraryFilterOptionsQuery.isLoading}
            />
          )}
          onChange={(_event, value) => {
            setFilterInput({ ...filterInput, stoplightType: value })
          }}
        />
      </Grid2>

      <Grid2 xs={12} sm={6} md={4}>
        <ListItem
          disablePadding
          secondaryAction={
            <Switch
              edge="end"
              checked={filterInput.verified}
              onChange={() => {
                setFilterInput({
                  ...filterInput,
                  verified: !filterInput.verified,
                })
              }}
            />
          }
        >
          <ListItemButton
            onClick={() => {
              setFilterInput({
                ...filterInput,
                verified: !filterInput.verified,
              })
            }}
          >
            <ListItemIcon>
              <VerifiedIcon
                color={filterInput.verified ? 'primary' : 'inherit'}
              />
            </ListItemIcon>
            <ListItemText primary={t('views.indicator.create.verified')} />
          </ListItemButton>
        </ListItem>
      </Grid2>

      {showManagementFeatures && (
        <Grid2 xs={12} display="flex" justifyContent="flex-end">
          <Button
            color="primary"
            variant="outlined"
            onClick={handleCreate}
            endIcon={<AddIcon />}
          >
            {t('views.economic.form.create')}
          </Button>
        </Grid2>
      )}
    </Grid2>
  )
}

interface LoadingTextInputProps {
  label: string
  isLoading?: boolean
  params: AutocompleteRenderInputParams
}

function LoadingTextInput({
  isLoading = false,
  label,
  params,
}: LoadingTextInputProps) {
  return (
    <TextField
      {...params}
      label={label}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {isLoading && <CircularProgress size={20} />}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  )
}
