import { type ServerResponse, axios } from '~/axios'
import { type EconomicLibraryQuestion } from '~/utils/types/economic-library'

export async function getListLibraryQuestions(filters: {
  language: string
  status: string
  country: string
  measurementUnit: string
  stoplightType: string
  filter: string
  verified: boolean
  page: number
}) {
  interface ListEconomicLibraryQuestions {
    content: EconomicLibraryQuestion[]
    totalPages: number
  }

  const query = /* GraphQL */ `
    query listEconomicLibraryQuestions(
      $language: String
      $status: String
      $country: String
      $measurementUnit: String
      $stoplightType: String
      $verified: Boolean
      $filter: String
      $page: Int
    ) {
      listEconomicLibraryQuestions(
        language: $language
        status: $status
        country: $country
        measurementUnit: $measurementUnit
        stoplightType: $stoplightType
        verified: $verified
        filter: $filter
        page: $page
      ) {
        content {
          id
          codeName
          questionText
          shortName
          options
          language
          country
          verified
          measurementUnit
          status
          stoplightType
          answerType
          topic
          topicInfo
        }
        totalPages
      }
    }
  `

  return await axios<
    ServerResponse<{
      listEconomicLibraryQuestions: ListEconomicLibraryQuestions
    }>
  >({
    method: 'post',
    url: '/graphql',
    data: { query, variables: { ...filters } },
  })
}

export interface EconomicLibraryQuestionModelInput {
  topic: string
  codeName: string
  language: string
  shortName: string
  topicInfo: string
  verified: boolean
  options: string[]
  answerType: string
  questionText: string
  measurementUnit: string
}

export async function updateEconomicQuestion(
  economicQuestion: EconomicLibraryQuestionModelInput & { id: number },
) {
  const updateQuery = /* GraphQL */ `
    mutation updateEconomicLibraryQuestion(
      $question: EconomicLibraryQuestionModelInput
    ) {
      updateEconomicLibraryQuestion(question: $question) {
        successful
      }
    }
  `

  return await axios<ServerResponse<{ successful: boolean }>>({
    method: 'post',
    url: '/graphql',
    data: {
      query: updateQuery,
      variables: { question: economicQuestion },
    },
  })
}

export async function createEconomicQuestion(
  economicQuestion: EconomicLibraryQuestionModelInput,
) {
  const createQuery = /* GraphQL */ `
    mutation createEconomicLibraryQuestion(
      $question: EconomicLibraryQuestionModelInput
    ) {
      createEconomicLibraryQuestion(question: $question) {
        successful
      }
    }
  `

  return await axios<ServerResponse<{ successful: boolean }>>({
    method: 'post',
    url: '/graphql',
    data: {
      query: createQuery,
      variables: { question: economicQuestion },
    },
  })
}

export async function deleteLibraryQuestion(question) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation deleteEconomicLibraryQuestion($question: EconomicLibraryQuestionModelInput) {deleteEconomicLibraryQuestion(question: $question)  { successful  }}',
      variables: {
        question,
      },
    }),
  })
}

export async function verifiedCodenames(onlyWithTranslations: boolean) {
  const query = /* GraphQL */ `
    query AllVerifiedCodeNames($onlyWithTranslations: Boolean) {
      allVerifiedCodeNames(onlyWithTranslations: $onlyWithTranslations)
    }
  `

  return await axios<ServerResponse<{ allVerifiedCodeNames: string[] }>>({
    method: 'post',
    url: '/graphql',
    data: {
      query,
      variables: {
        onlyWithTranslations,
      },
    },
  })
}

export async function verifiedEconomicCodenames() {
  const query = /* GraphQL */ `
    query ListVerifiedEconomicCodenames {
      listVerifiedEconomicCodenames
    }
  `

  return await axios<
    ServerResponse<{ listVerifiedEconomicCodenames: string[] }>
  >({
    method: 'post',
    url: '/graphql',
    data: {
      query,
    },
  })
}
