import { createQueryKeys } from '@lukemorales/query-key-factory'

import { getIndicatorsBySurveyId } from '~/api'
import store from '~/redux'
import { type SearchSnapshotsProps, searchSnaspshots } from '~/services/reports'
import { type PlatformLanguage } from '~/utils/types/i18n'

const userId = store.getState().user?.id

export const reportsQueries = createQueryKeys('reports', {
  getIndicatorsBySurveyId: (surveyId: number, language: PlatformLanguage) => ({
    queryKey: [language, { surveyId, userId }],
    queryFn: async () => await getIndicatorsBySurveyId(surveyId),
  }),
  searchSnapshots: (
    searchSnapshotsProps: SearchSnapshotsProps,
    language: PlatformLanguage,
  ) => ({
    queryKey: [language, { searchSnapshotsProps, userId }],
    queryFn: async () => await searchSnaspshots(searchSnapshotsProps),
  }),
})
