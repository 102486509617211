import { createQueryKeys } from '@lukemorales/query-key-factory'

import { supportedLanguages } from '~/api'
import store from '~/redux'
import { type PlatformLanguage } from '~/utils/types/i18n'

const userId = store.getState().user?.id

export const languagesQueries = createQueryKeys('languages', {
  supportedLanguages: (language: PlatformLanguage) => ({
    queryKey: [language, { userId }],
    queryFn: async () => await supportedLanguages(),
  }),
})
