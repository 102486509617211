import {
  CheckCircle as CheckIcon,
  Close as CloseIcon,
} from '@mui/icons-material'
import { Grid, IconButton, Modal, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useQuery } from '@tanstack/react-query'

import { queries } from '~/queries'
import { type EconomicLibraryQuestion } from '~/utils/types/economic-library'
import { type PlatformLanguage } from '~/utils/types/i18n'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
    maxHeight: '100vh',
  },
  container: {
    padding: '2em',
    backgroundColor: theme.palette.background.default,
    outline: 'none',
    width: '85vw',
    maxWidth: 1000,
    height: '50vh',
    minHeight: 500,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '1em',
      paddingTop: '2.5rem',
      height: '100vh',
      width: '100vw',
    },
    overflowY: 'auto',
  },
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    marginBottom: 15,
  },
  columnContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '8px 0',
  },
  label: {
    fontFamily: 'Open Sans',
    fontWeight: 500,
  },
  answer: {
    fontFamily: 'Open Sans',
    color: 'rgba(0,0,0,0.5)',
    textAlign: 'justify',
  },
}))

interface EconomicLibraryDetailsModalProps {
  open: boolean
  question: EconomicLibraryQuestion | null
  onClose: () => void
}

export default function EconomicLibraryDetailsModal({
  open,
  question,
  onClose,
}: EconomicLibraryDetailsModalProps) {
  const classes = useStyles()
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const platformLanguage = language as PlatformLanguage

  const languagesQuery = useQuery({
    ...queries.languages.supportedLanguages(platformLanguage),
    staleTime: Infinity,
    select: data => data.data.data.supportedLanguages,
  })

  const findLanguage = useMemo(() => {
    if (!languagesQuery.isSuccess) return null
    return languagesQuery.data.find(l => l.code === question?.language)
  }, [languagesQuery.data, languagesQuery.isSuccess, question?.language])

  const isQuestionWithOptions = useMemo(() => {
    if (!question?.answerType) return false

    return ['select', 'radio', 'checkbox'].includes(question?.answerType)
  }, [question?.answerType])

  if (!question) return null
  return (
    <Modal
      disableEnforceFocus
      disableAutoFocus
      className={classes.modal}
      open={open}
      onClose={onClose}
    >
      <div className={classes.container}>
        <Typography
          variant="h5"
          align="center"
          style={{ marginBottom: '2rem' }}
        >
          {t('views.economic.form.details')}
        </Typography>
        <IconButton
          size="large"
          key="dismiss"
          className={classes.closeIcon}
          onClick={() => {
            onClose()
          }}
        >
          <CloseIcon style={{ color: 'green' }} />
        </IconButton>

        <Grid container>
          <Grid item md={8} xs={12}>
            <TextAnswer
              label={`${t('views.indicator.create.indicatorTitle')}:`}
              answer={question.questionText}
            />
          </Grid>
          <Grid item md={4} xs={12} container>
            {question.verified && (
              <Tooltip title={t('views.indicator.create.verified')}>
                <CheckIcon style={{ color: '#309E43' }} />
              </Tooltip>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={4} sm={6} xs={12}>
            <TextAnswer
              label={`${t('views.indicator.create.shortName')}:`}
              answer={question.shortName}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <TextAnswer
              label={`${t('views.indicator.create.codeName')}:`}
              answer={question.codeName}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={4} sm={6} xs={12}>
            <TextAnswer
              label={`${t('views.languageFilter.label')}:`}
              answer={findLanguage?.description ?? ''}
            />
          </Grid>

          <Grid item md={4} sm={6} xs={12}>
            <TextAnswer
              label={t('views.economic.form.answerType')}
              answer={question.answerType ? question.answerType : ''}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextAnswer
              label={t('views.indicator.filter.stoplights')}
              answer={question.stoplightType ? question.stoplightType : ''}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={4} sm={6} xs={12}>
            <TextAnswer
              label={`${t('views.economic.form.topic')}:`}
              answer={question.topic}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextAnswer
              label={`${t('views.solutions.form.country')}:`}
              answer={question.country ? question.country : ''}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextAnswer
              label={`${t('views.indicator.filter.measurement')}:`}
              answer={question.measurementUnit ? question.measurementUnit : ''}
            />
          </Grid>
        </Grid>

        {isQuestionWithOptions && (
          <Grid container>
            <Grid item md={4} sm={6} xs={12}>
              <ArrayAnswer
                label={`${t('views.surveyBuilder.options')}:`}
                answers={question.options}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </Modal>
  )
}

interface TextAnswerProps {
  label: string
  answer: string
}

function TextAnswer({ label, answer }: TextAnswerProps) {
  const classes = useStyles()

  return (
    <div className={classes.columnContainer}>
      <Typography variant="h6" align="center" className={classes.label}>
        {label}
      </Typography>
      <Typography variant="h6" align="center" className={classes.answer}>
        {answer}
      </Typography>
    </div>
  )
}

interface ArrayAnswerProps {
  label: string
  answers: string[]
}

function ArrayAnswer({ label, answers }: ArrayAnswerProps) {
  const classes = useStyles()
  return (
    <div className={classes.columnContainer}>
      <Typography variant="h6" align="center" className={classes.label}>
        {label}
      </Typography>
      {Array.isArray(answers) && (
        <ul style={{ margin: 0 }}>
          {answers.map((text, index) => (
            <Typography
              key={index}
              variant="h6"
              className={classes.answer}
              style={{ margin: 0 }}
            >
              <li>{text}</li>
            </Typography>
          ))}
        </ul>
      )}
    </div>
  )
}
