import { type ServerResponse, axios } from '~/axios'

export interface SearchSnapshotsProps {
  hubIds: number[]
  orgIds: number[]
  projectIds: number[]
  facilitatorIds: number[]
  stoplightFilters: Array<{ codeName: string; values: Array<'1' | '2' | '3'> }>

  shouldIncludeFollowupSurveys: boolean

  page: number
  surveyDefinitionId: number

  fromDate: number | null
  toDate: number | null

  sortBy: 'familyName' | 'familyCode' | ''
  sortDirection: 'asc' | 'desc' | ''
}

export async function searchSnaspshots(filters: SearchSnapshotsProps) {
  const query = /* GraphQL */ `
    query SearchSnapshots(
      $page: Int
      $hubs: [Long]
      $orgs: [Long]
      $toDate: Long
      $fromDate: Long
      $sortBy: String
      $projects: [Long]
      $surveyUsers: [Long]
      $sortDirection: String
      $surveyDefinition: Long!
      $followupSurveys: Boolean
      $stoplightFilters: [StoplightFilterInput]
    ) {
      searchSnapshots(
        hubs: $hubs
        orgs: $orgs
        page: $page
        toDate: $toDate
        sortBy: $sortBy
        fromDate: $fromDate
        projects: $projects
        surveyUsers: $surveyUsers
        sortDirection: $sortDirection
        followupSurveys: $followupSurveys
        surveyDefinition: $surveyDefinition
        stoplightFilters: $stoplightFilters
      ) {
        page
        totalElements
        totalPages
        additionalData
        content {
          familyId: family
          familyName
          familyCode
          surveyDate
          surveyNumber
        }
      }
    }
  `

  return await axios<
    ServerResponse<{
      searchSnapshots: {
        page: number
        totalPages: number
        totalElements: number
        additionalData?: {
          distinctFamilies: string
        }
        content: Array<{
          familyId: number
          familyName: string
          familyCode: string
          surveyDate: number
          surveyNumber: number
        }>
      }
    }>
  >({
    method: 'post',
    url: '/graphql',
    data: {
      query,
      variables: {
        page: filters.page,
        hubs: filters.hubIds,
        orgs: filters.orgIds,
        toDate: filters.toDate,
        sortBy: filters.sortBy,
        fromDate: filters.fromDate,
        projects: filters.projectIds,
        surveyUsers: filters.facilitatorIds,
        sortDirection: filters.sortDirection,
        stoplightFilters: filters.stoplightFilters,
        surveyDefinition: filters.surveyDefinitionId,
        followupSurveys: filters.shouldIncludeFollowupSurveys,
      },
    },
  })
}
