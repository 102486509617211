import {
  CheckBox as Checkbox,
  Event as Date,
  ArrowDropDownCircle as DropDown,
  LooksOne as Number,
  RadioButtonChecked as Radio,
  ShortText as Text,
} from '@mui/icons-material'

interface QuestionIconProps {
  type: string
}

const iconsByType = {
  text: <Text color="primary" />,
  date: <Date color="primary" />,
  radio: <Radio color="primary" />,
  number: <Number color="primary" />,
  select: <DropDown color="primary" />,
  boolean: <Checkbox color="primary" />,
  checkbox: <Checkbox color="primary" />,
  dropdown: <DropDown color="primary" />,
}

// TODO: Make this a simple function and not an unnecessary component
export default function EconomicQuestionIcon({ type }: QuestionIconProps) {
  return iconsByType[type]
}
