import { createQueryKeys } from '@lukemorales/query-key-factory'

import { getLibraryFilterOptions } from '~/api'
import store from '~/redux'
import {
  createEconomicQuestion,
  getListLibraryQuestions,
  updateEconomicQuestion,
  verifiedCodenames,
  verifiedEconomicCodenames,
} from '~/services/economic-library'
import { type EconomicLibraryQuestion } from '~/utils/types/economic-library'
import { type PlatformLanguage } from '~/utils/types/i18n'

const userId = store.getState().user?.id

export const librariesQueries = createQueryKeys('libraries', {
  getLibraryFilterOptions: (language: PlatformLanguage) => ({
    queryKey: [language, { userId }],
    queryFn: async () => await getLibraryFilterOptions(),
  }),

  getListLibraryQuestions: (filters: {
    status: string
    filter: string
    country: string
    language: string
    verified: boolean
    stoplightType: string
    measurementUnit: string
  }) => ({
    queryKey: [filters, { userId }],
    queryFn: async ({ pageParam = 0 }) => {
      const result = await getListLibraryQuestions({
        ...filters,
        page: pageParam,
      })

      return result.data.data.listEconomicLibraryQuestions
    },
  }),

  verifiedCodenames: (onlyWithTranslations: boolean) => ({
    queryKey: [onlyWithTranslations, { userId }],
    queryFn: async () => await verifiedCodenames(onlyWithTranslations),
  }),

  verifiedEconomicCodenames: () => ({
    queryKey: [{ userId }],
    queryFn: async () => await verifiedEconomicCodenames(),
  }),

  updateEconomicQuestion: (newQuestion: EconomicLibraryQuestion) => ({
    queryKey: [{ newQuestion, userId }],
    queryFn: async () => await updateEconomicQuestion(newQuestion),
  }),

  createEconomicQuestion: (newQuestion: EconomicLibraryQuestion) => ({
    queryKey: [{ newQuestion, userId }],
    queryFn: async () => await createEconomicQuestion(newQuestion),
  }),
})
