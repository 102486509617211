import { type FocusEventHandler } from 'react'

import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from '~/redux/hooks'

import { getProjectsByOrganization } from '../../api'

const ALL_PROJECTS_VALUE = -1

export interface ProjectItem {
  label: string
  value: number
}

interface MultiSelectorProps {
  isMulti: true
  value: ProjectItem[]
  onChange: (selected: ProjectItem[], allProjects: ProjectItem[]) => void
}

interface SingleSelectorProps {
  isMulti: false
  value: ProjectItem
  onChange: (selected: ProjectItem | null, allProjects: ProjectItem[]) => void
}

type ProjectsSelectorProps = {
  hubId?: number | null
  organizationIds?: number[]

  onBlur?: FocusEventHandler<HTMLDivElement>
} & (MultiSelectorProps | SingleSelectorProps)

export default function ProjectsSelector({
  value,
  isMulti,

  hubId,
  organizationIds = [],

  onBlur,
  onChange,
}: ProjectsSelectorProps) {
  const { t } = useTranslation()

  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })

  const orgIds: number[] = useMemo(() => {
    if (isMulti && organizationIds?.length > 0) return organizationIds
    if (user.organization?.id) return [user.organization.id]

    return []
  }, [isMulti, user.organization?.id, organizationIds])

  // TODO: Migrate to query factory
  const projectsByOrgQuery = useQuery({
    queryKey: ['projects-by-org', orgIds, user, hubId],
    queryFn: async () => await getProjectsByOrganization(orgIds, hubId),
    select: ({ data }) => {
      return data.data.projectsByOrganization.map(p => ({
        label: p.title,
        value: p.id,
      }))
    },
  })

  const allProjectsOption = {
    label: t('views.projectFilter.allProjects'),
    value: ALL_PROJECTS_VALUE,
  } as const

  let projectsToShow: ProjectItem[] = []
  const projects = projectsByOrgQuery.data ?? []

  if (isMulti) {
    projectsToShow =
      projects.length !== value?.length && projects.length > 1
        ? [allProjectsOption, ...projects]
        : [...projects]

    if (value?.some(d => d.value === ALL_PROJECTS_VALUE)) {
      projectsToShow = []
    }
  } else {
    projectsToShow = [...projects]
  }

  const shouldUseOverflowHack =
    (Array.isArray(value) && value.length > 0) ||
    (!Array.isArray(value) && !!value)

  return (
    <Autocomplete
      limitTags={3}
      autoHighlight
      onBlur={onBlur}
      multiple={isMulti}
      value={value}
      disableCloseOnSelect
      options={projectsToShow}
      loadingText={t('views.projectFilter.loading')}
      noOptionsText={t('views.projectFilter.noOption')}
      loading={projectsByOrgQuery.isLoading}
      getOptionKey={project => project.value}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(_event, newValue) => {
        if (isMulti && Array.isArray(newValue)) {
          const isAllProjectsSelected = newValue.some(
            p => p.value === ALL_PROJECTS_VALUE,
          )
          const selectedProjects = isAllProjectsSelected ? projects : newValue

          onChange(selectedProjects, projects)
        }

        if (!isMulti && !Array.isArray(newValue)) {
          onChange(newValue, projects)
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={t('views.projectFilter.label')}
          InputProps={{
            ...params.InputProps,
            startAdornment: shouldUseOverflowHack && (
              <Box sx={{ maxHeight: 120, overflowY: 'auto' }}>
                {params.InputProps.startAdornment}
              </Box>
            ),
            endAdornment: (
              <>
                {projectsByOrgQuery.isLoading && <CircularProgress size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
