import {
  type inferQueryKeyStore,
  mergeQueryKeys,
} from '@lukemorales/query-key-factory'

import { languagesQueries } from '~/queries/languages'
import { librariesQueries } from '~/queries/libraries'
import { mapsQueries } from '~/queries/maps'
import { organizationQueries } from '~/queries/organizations'
import { projectsQueries } from '~/queries/projects'
import { reportsQueries } from '~/queries/reports'
import { solutionsQueries } from '~/queries/solutions'

export const queries = mergeQueryKeys(
  organizationQueries,
  solutionsQueries,
  mapsQueries,
  projectsQueries,
  languagesQueries,
  reportsQueries,
  librariesQueries,
)
export type Queries = inferQueryKeyStore<typeof queries>
