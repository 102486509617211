import { StrictMode } from 'react'

import * as Sentry from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import { createRoot } from 'react-dom/client'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import App from './App'
import './i18n'
import reportWebVitals from './reportWebVitals'
import { getEnvironment } from './utils/role-utils'

const currentEnvironment = getEnvironment()

mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN ?? 'fake-token', {
  ignore_dnt: true, // If the user has "do not track" activated, we just ignore it
})

Sentry.init({
  environment: currentEnvironment,
  enabled: currentEnvironment !== 'testing',
  dsn: import.meta.env.VITE_SENTRY_DSN,
  tunnel: '/unblocksentry',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'localhost',
    /https:\/\/*\.backend\.povertystoplight\.org\/*/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  ignoreErrors: [
    // NOTE: Ignoring because we don't use ResizeObserver directly and libraries
    // will fix this at some point, or we will fix it by migrating away from
    // those libraries that are not compliant.
    // https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/
    'ResizeObserver loop limit exceeded',
  ],
})

const container = document.getElementById('root')
if (!container) throw new Error('Root container not found')

const root = createRoot(container)
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
