import { zodResolver } from '@hookform/resolvers/zod'
import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Stack,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'
import {
  AutocompleteElement,
  Controller,
  SwitchElement,
  TextFieldElement,
  useForm,
} from 'react-hook-form-mui'
import { z } from 'zod'

import EconomicQuestionIcon from '~/components/EconomicQuestionIcon'
import { queries } from '~/queries'
import {
  createEconomicQuestion,
  updateEconomicQuestion,
} from '~/services/economic-library'
import { useLocalizedCountries } from '~/utils/hooks/useLocalizedCountries'
import { getLanguageByCode } from '~/utils/lang-utils'
import { type EconomicLibraryQuestion } from '~/utils/types/economic-library'
import { type PlatformLanguage } from '~/utils/types/i18n'
import { excludeFalsyWithMessage } from '~/utils/zod'

import EconomicOptionForm from '../survey-builder/economic/EconomicOptionForm'

type AnswerType = EconomicLibraryQuestion['answerType']
interface EconomicLibraryFormProps {
  isOpen: boolean
  question: EconomicLibraryQuestion | null
  onClose: ({ didSubmit }: { didSubmit: boolean }) => void
}

export default function EconomicLibraryForm({
  isOpen,
  question,
  onClose,
}: EconomicLibraryFormProps) {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  const { i18n } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const platformLanguage = language as PlatformLanguage

  const fieldIsRequired = t('validation.fieldIsRequired')

  const OTHER_OPTION = useMemo(() => {
    return { value: 'OTHER', label: t('general.other') }
  }, [t])

  const answerTypesOptions: Array<{
    label: string
    value: AnswerType
  }> = [
    { value: 'text', label: t('answerType.text') },
    { value: 'radio', label: t('answerType.radio') },
    { value: 'select', label: t('answerType.select') },
    { value: 'number', label: t('answerType.number') },
    { value: 'checkbox', label: t('answerType.checkbox') },
  ]

  const libraryFilterOptionsQuery = useQuery({
    ...queries.libraries.getLibraryFilterOptions(platformLanguage),
    staleTime: Infinity,
    select: data => data.data.data.listLibraryOptions,
  })

  const createEconomicQuestionMutation = useMutation({
    mutationFn: createEconomicQuestion,
    onSuccess: () => {
      enqueueSnackbar(t('views.economic.form.saved'), {
        variant: 'success',
      })
      onClose({ didSubmit: true })
    },
    onError: () => {
      enqueueSnackbar(t('views.economic.form.saveError'), {
        variant: 'error',
      })
    },
  })

  const updateEconomicQuestionMutation = useMutation({
    mutationFn: updateEconomicQuestion,
    onSuccess: () => {
      enqueueSnackbar(t('views.economic.form.saved'), {
        variant: 'success',
      })
      onClose({ didSubmit: true })
    },
    onError: () => {
      enqueueSnackbar(t('views.economic.form.saveError'), {
        variant: 'error',
      })
    },
  })

  type FormValues = z.input<typeof validationSchema>
  type FormValuesOutput = z.output<typeof validationSchema>
  const validationSchema = z
    .object({
      language: z
        .object({ label: z.string(), value: z.string() })
        .nullable()
        .transform(excludeFalsyWithMessage(fieldIsRequired)),
      codeName: z
        .object({ value: z.string(), label: z.string() })
        .nullable()
        .transform(excludeFalsyWithMessage(fieldIsRequired)),
      measurementUnit: z
        .object({ code: z.string(), description: z.string() })
        .nullable(),
      stoplightType: z
        .object({ code: z.string(), description: z.string() })
        .nullable(),
      verified: z.boolean().default(false),
      otherCodeName: z
        .string()
        .trim()
        .min(1, fieldIsRequired)
        .regex(/^\S+$/g, { message: t('economicLibrary.noSpacesAllowed') })
        .regex(/^[a-zA-Z]+$/, {
          message: t('economicLibrary.noSpecialCharacters'),
        })
        .regex(/^[^A-Z].*$/, {
          message: t('economicLibrary.firstLetterLowercase'),
        })
        .optional(),
      country: z.object({ label: z.string(), code: z.string() }).nullable(),
      questionText: z.string().trim().min(1, fieldIsRequired),
      topic: z.string().trim().min(1, fieldIsRequired),
      topicInfo: z.string().trim(),
      shortName: z.string().trim().min(1, fieldIsRequired),
      answerType: z
        .object({
          label: z.string(),
          value: z.union([
            z.literal('text'),
            z.literal('radio'),
            z.literal('select'),
            z.literal('number'),
            z.literal('checkbox'),
          ]),
        })
        .nullable()
        .transform(excludeFalsyWithMessage(fieldIsRequired)),
      options: z.array(
        z.object({
          text: z.string(),
          value: z.string(),
          otherOption: z.boolean().default(false),
        }),
      ),
    })
    .superRefine((values, ctx) => {
      if (values.codeName.value === OTHER_OPTION.value) {
        if (!values.otherCodeName) {
          ctx.addIssue({
            code: 'custom',
            message: t('validation.fieldIsRequired'),
            path: ['otherCodeName'],
          })
        }
      }

      const answerTypeWithOptions: AnswerType[] = [
        'checkbox',
        'select',
        'radio',
      ]
      if (answerTypeWithOptions.includes(values.answerType.value)) {
        const hasEmptyOptions = values.options.some(o => !o.value.trim())
        if (hasEmptyOptions) {
          ctx.addIssue({
            code: 'custom',
            message: t('economicLibrary.emptyOptions'),
            path: ['options'],
          })
        }
      }
    })

  async function onSubmit(values: FormValuesOutput) {
    const newQuestion = {
      id: question?.id ?? null,
      answerType: values.answerType.value,
      topic: values.topic,
      verified: values.verified,
      shortName: values.shortName,
      topicInfo: values.topicInfo,
      language: values.language.value,
      status: question?.status ?? null,
      questionText: values.questionText,
      stoplightType: values.stoplightType?.code ?? '',
      options: values.options.map(option => option.text),
      country: values.country ? values.country.code : null,
      codeName: values.otherCodeName
        ? values.otherCodeName
        : values.codeName.value,
      measurementUnit: values.measurementUnit
        ? values.measurementUnit.code
        : '',
    }

    if (newQuestion.id) {
      await updateEconomicQuestionMutation.mutateAsync({
        ...newQuestion,
        id: newQuestion.id,
      })
    } else {
      await createEconomicQuestionMutation.mutateAsync(newQuestion)
    }
  }

  const lang = getLanguageByCode(i18n.language as PlatformLanguage)
  const countryOptions = useLocalizedCountries(lang)

  const countryByCode = useMemo(() => {
    const country = countryOptions.find(c => c.code === question?.country)
    return country ?? null
  }, [countryOptions, question?.country])

  const languagesQuery = useQuery({
    ...queries.languages.supportedLanguages(platformLanguage),
    staleTime: Infinity,
    select: data =>
      data.data.data.supportedLanguages.map(l => ({
        label: l.description,
        value: l.code,
      })),
  })

  const verifiedEconomicCodenamesQuery = useQuery({
    ...queries.libraries.verifiedEconomicCodenames(),
    select: data => data.data.data.listVerifiedEconomicCodenames,
    staleTime: 5 * 60 * 1000, // 5 minutes
  })

  const codeNameOptions: Array<FormValuesOutput['codeName']> = useMemo(() => {
    if (!verifiedEconomicCodenamesQuery.isSuccess) return []
    const options: Array<FormValuesOutput['codeName']> =
      verifiedEconomicCodenamesQuery.data.map(codeName => ({
        value: codeName,
        label: codeName,
      }))

    return [...options, OTHER_OPTION]
  }, [
    OTHER_OPTION,
    verifiedEconomicCodenamesQuery.data,
    verifiedEconomicCodenamesQuery.isSuccess,
  ])

  const form = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      language: null,
      codeName: null,
      otherCodeName: '',
      stoplightType: null,
      measurementUnit: null,
      country: countryByCode,
      topic: question?.topic ?? '',
      topicInfo: question?.topicInfo ?? '',
      shortName: question?.shortName ?? '',
      questionText: question?.questionText ?? '',
      options: question?.options.map(o => ({ text: o, value: o })) ?? [],
      answerType:
        answerTypesOptions.find(
          answerType => answerType.value === question?.answerType,
        ) ?? null,
    },
  })

  const codeNameWatch = form.watch('codeName')
  const answerTypeWatch = form.watch('answerType')
  const isQuestionWithOptions = useMemo(() => {
    if (!answerTypeWatch) return false

    return ['select', 'radio', 'checkbox'].includes(answerTypeWatch.value)
  }, [answerTypeWatch])

  useEffect(() => {
    //* NOTE: Language Setter
    if (!languagesQuery.isSuccess) return

    const questionLanguage = languagesQuery.data.find(
      l => l.value === question?.language,
    )
    if (questionLanguage) {
      form.setValue('language', questionLanguage)
    }
  }, [form, languagesQuery.data, languagesQuery.isSuccess, question?.language])

  useEffect(() => {
    //* NOTE: Codename Setter
    if (!question?.codeName) return
    if (!verifiedEconomicCodenamesQuery.isSuccess) return

    const codeName = verifiedEconomicCodenamesQuery.data.find(
      c => c === question.codeName,
    )

    if (codeName) {
      form.setValue('codeName', { label: codeName, value: codeName })
    } else {
      form.setValue('codeName', OTHER_OPTION)
      form.setValue('otherCodeName', question.codeName)
    }
  }, [
    form,
    OTHER_OPTION,
    question?.codeName,
    verifiedEconomicCodenamesQuery.data,
    verifiedEconomicCodenamesQuery.isSuccess,
  ])

  useEffect(() => {
    //* NOTE: Measurement Unit | Stoplight Setter
    if (!libraryFilterOptionsQuery.isSuccess) return
    const { measurementUnits, stoplights } = libraryFilterOptionsQuery.data

    const unit = measurementUnits.find(
      m => m.code === question?.measurementUnit,
    )
    if (unit) {
      form.setValue('measurementUnit', unit)
    }
    const stoplightType = stoplights.find(
      s => s.code === question?.stoplightType,
    )
    if (stoplightType) {
      form.setValue('stoplightType', stoplightType)
    }
  }, [
    form,
    question?.stoplightType,
    question?.measurementUnit,
    libraryFilterOptionsQuery.data,
    libraryFilterOptionsQuery.isSuccess,
  ])

  return (
    <Modal
      open={isOpen}
      disableEnforceFocus
      onClose={() => {
        if (!form.formState.isLoading) {
          onClose({ didSubmit: false })
        }
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        noValidate
        component="form"
        variant="outlined"
        onSubmit={form.handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          overflow: 'auto',
          flexDirection: 'column',
          width: { xs: '90%', md: '75%' },
          height: { xs: '90%', md: '75%' },
        }}
      >
        <CardHeader
          titleTypographyProps={{ align: 'center' }}
          title={
            question?.id
              ? t('views.economic.form.edit')
              : t('views.economic.form.create')
          }
          action={
            <IconButton
              color="primary"
              onClick={() => {
                onClose({ didSubmit: false })
              }}
            >
              <CloseIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Grid2 container spacing={2}>
            <Grid2 xs={12} md={8} lg={10}>
              <TextFieldElement
                required
                fullWidth
                autoFocus
                name="questionText"
                control={form.control}
                label={t('views.economic.form.title')}
              />
            </Grid2>

            <Grid2 xs={12} md={4} lg={2} justifyContent="center" display="flex">
              <SwitchElement
                autoFocus
                name="verified"
                control={form.control}
                label={t('views.indicator.create.verified')}
              />
            </Grid2>

            <Grid2 xs={12} sm={6}>
              <TextFieldElement
                required
                fullWidth
                name="shortName"
                control={form.control}
                label={t(`views.indicator.create.shortName`)}
                inputProps={{ maxLength: '255' }}
              />
            </Grid2>

            <Grid2 xs={12} sm={6}>
              <TextFieldElement
                required
                fullWidth
                name="topic"
                control={form.control}
                label={t('views.economic.form.topic')}
                inputProps={{ maxLength: '255' }}
              />
            </Grid2>

            <Grid2 xs={12}>
              <TextFieldElement
                fullWidth
                multiline
                minRows={2}
                maxRows={5}
                name="topicInfo"
                control={form.control}
                label={t('views.economic.form.topicInfo')}
              />
            </Grid2>

            <Grid2 xs={12} sm={6}>
              <Stack gap={2}>
                <AutocompleteElement
                  required
                  name="codeName"
                  control={form.control}
                  label={t('views.indicator.create.codeName')}
                  options={codeNameOptions}
                  loading={verifiedEconomicCodenamesQuery.isLoading}
                  autocompleteProps={{
                    autoHighlight: true,
                    onChange: () => {
                      form.setValue('otherCodeName', '')
                    },
                    isOptionEqualToValue: (option, value) => {
                      if (!value) return false
                      return option.value === value.value
                    },
                  }}
                />

                {codeNameWatch?.value === OTHER_OPTION.value && (
                  <TextFieldElement
                    required
                    fullWidth
                    name="otherCodeName"
                    control={form.control}
                    label={t('general.other')}
                  />
                )}
              </Stack>
            </Grid2>

            <Grid2 xs={12} sm={6}>
              <AutocompleteElement
                required
                name="language"
                control={form.control}
                label={t('views.languageFilter.label')}
                options={languagesQuery.data ?? []}
                loading={languagesQuery.isLoading}
                autocompleteProps={{
                  autoHighlight: true,
                  isOptionEqualToValue: (option, value) => {
                    if (!value) return false
                    return option.value === value.value
                  },
                }}
              />
            </Grid2>

            <Grid2 xs={12} sm={6}>
              <AutocompleteElement
                name="stoplightType"
                control={form.control}
                label={t('views.indicator.filter.stoplights')}
                loading={libraryFilterOptionsQuery.isLoading}
                options={libraryFilterOptionsQuery.data?.stoplights ?? []}
                autocompleteProps={{
                  autoHighlight: true,
                  getOptionLabel: option => option.description,
                  isOptionEqualToValue: (option, value) => {
                    if (!value) return false
                    return option.code === value.code
                  },
                }}
              />
            </Grid2>

            <Grid2 xs={12} sm={6}>
              <AutocompleteElement
                name="country"
                control={form.control}
                label={t('views.solutions.form.country')}
                options={countryOptions}
                autocompleteProps={{
                  autoHighlight: true,
                  getOptionLabel: option => option.label,
                  isOptionEqualToValue: (option, value) => {
                    if (!value) return false
                    return option.code === value.code
                  },
                }}
              />
            </Grid2>

            <Grid2 xs={12} sm={6}>
              <AutocompleteElement
                required
                name="answerType"
                control={form.control}
                label={t('views.economic.form.answerType')}
                options={answerTypesOptions}
                autocompleteProps={{
                  autoHighlight: true,
                  onChange: (_event, newValue) => {
                    if (!newValue) return
                    const { value } = newValue
                    if (value === 'number' || value === 'text') {
                      form.setValue('options', [])
                    }
                  },
                  isOptionEqualToValue: (option, value) => {
                    if (!value) return false
                    return option.value === value.value
                  },
                  renderOption: (props, option) => (
                    <ListItem dense {...props} key={option.value}>
                      <ListItemIcon>
                        <EconomicQuestionIcon type={option.value} />
                      </ListItemIcon>
                      <ListItemText primary={option.label} />
                    </ListItem>
                  ),
                }}
              />
            </Grid2>

            <Grid2 xs={12} sm={6}>
              <AutocompleteElement
                name="measurementUnit"
                control={form.control}
                label={t('views.indicator.filter.measurement')}
                options={libraryFilterOptionsQuery.data?.measurementUnits ?? []}
                loading={libraryFilterOptionsQuery.isLoading}
                autocompleteProps={{
                  autoHighlight: true,
                  getOptionLabel: option => option.description,
                  isOptionEqualToValue: (option, value) => {
                    if (!value) return false
                    return option.code === value.code
                  },
                }}
              />
            </Grid2>

            {isQuestionWithOptions && (
              <Grid2 xs={12}>
                <Controller
                  name="options"
                  control={form.control}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl fullWidth error={fieldState.invalid}>
                        <EconomicOptionForm
                          options={field.value}
                          updateOptions={async options => {
                            field.onChange(options)
                          }}
                        />
                        <FormHelperText>
                          {fieldState.error?.message}
                        </FormHelperText>
                      </FormControl>
                    )
                  }}
                />
              </Grid2>
            )}
          </Grid2>
        </CardContent>

        <Divider sx={{ mt: 'auto' }} variant="middle" />

        <CardActions sx={{ justifyContent: 'space-around' }}>
          <Button
            variant="outlined"
            startIcon={<CloseIcon />}
            disabled={form.formState.isSubmitting}
            onClick={() => {
              onClose({ didSubmit: false })
            }}
          >
            {t('general.close')}
          </Button>
          <LoadingButton
            type="submit"
            variant="outlined"
            endIcon={<SaveIcon />}
            loading={form.formState.isSubmitting}
          >
            {t('general.save')}
          </LoadingButton>
        </CardActions>
      </Card>
    </Modal>
  )
}
