import {
  type ServerResponse,
  axios,
  mapToBackendLanguageWithFallback,
} from '~/axios'
import { type PlatformLanguage } from '~/utils/types/i18n'
import { type Solution } from '~/utils/types/solution'

export interface CreateSolutionPayload {
  title: string
  description: string
  country: string
  contentText: string
  contentRich: string
  dimensionsNames: string[]
  dimensions: number[]
  indicatorsCodeNames: string[]
  indicatorsNames: string[]
  showAuthor: boolean
  organization: number | null
  hub: number | null
  stakeholder: number | null
  stoplightTypes: string[]
  resources: any[]
  contactInfo: string
  lang: string
  type: string | null
}

export type UpdateSolutionPayload = CreateSolutionPayload & {
  id: number
}

export async function saveOrUpdateSolution(
  payload: CreateSolutionPayload | UpdateSolutionPayload,
) {
  if (!('id' in payload)) {
    return await axios({
      method: 'post',
      url: '/graphql',
      data: JSON.stringify({
        query: `mutation createSolution($solution: StoplightSolutionModelInput) {createSolution(solution: $solution){id, title} }`,
        variables: {
          solution: payload,
        },
      }),
    })
  }

  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: `mutation updateSolution($solution: StoplightSolutionModelInput) {updateSolution(solution: $solution){title version resources{id url}}}`,
      variables: { solution: payload },
    }),
  })
}

export async function updateSolutionView(id: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation updateSolutionViews($solution: StoplightSolutionModelInput) {updateSolutionViews(solution: $solution){title views}}',
      variables: {
        solution: {
          id,
        },
      },
    }),
  })
}

export interface SolutionItem {
  id: number
  title: string
  country: string
  indicatorsNames: string[]
  description: string
  dimensionsNames: string[]
  views: number
}

export async function getSolutions(payload: {
  page: number
  country: string
  language: PlatformLanguage
  filter: string
  dimensions: number[]
  indicators: string[]
  solutionType: string
  stoplightTypes: string[]
}) {
  return await axios.post<
    ServerResponse<{
      solutions: {
        content: SolutionItem[]
        totalElements: number
        totalPages: number
      }
    }>
  >(
    '/graphql',
    JSON.stringify({
      query:
        'query solutions( $page: Int $country: String $name: String $lang: String $dimensions: [Long] $indicators: [String] $solutionType: String $stoplightTypes: [String] $organizations: [Long] $hub: Long $user: String $sortBy: String $sortDirection: String ) { solutions( page: $page country: $country name: $name lang: $lang dimensions: $dimensions indicators: $indicators solutionType: $solutionType stoplightTypes: $stoplightTypes organizations: $organizations hub: $hub user: $user sortBy: $sortBy sortDirection: $sortDirection ) { content { id title country indicatorsNames description dimensionsNames views } totalElements totalPages } }',
      variables: {
        page: payload.page,
        country: payload.country,
        lang: mapToBackendLanguageWithFallback(payload.language),
        name: payload.filter,
        dimensions: payload.dimensions,
        indicators: payload.indicators,
        solutionType: payload.solutionType,
        stoplightTypes: payload.stoplightTypes,
        organizations: [],
        hub: null,
        user: '',
      },
    }),
  )
}

export async function getSolutionsForFamily({
  page,
  country,
  lang,
  name,
}: {
  page: number
  country: string
  lang: PlatformLanguage
  name: string
}) {
  return await axios.post<
    ServerResponse<{
      solutionsForFamily: {
        content: SolutionItem[]
        totalElements: number
        totalPages: number
      }
    }>
  >(
    '/graphql',
    JSON.stringify({
      query:
        'query solutionsForFamily( $page: Int $country: String $name: String $lang: String $sortBy: String $sortDirection: String ) { solutionsForFamily( page: $page country: $country name: $name lang: $lang sortBy: $sortBy sortDirection: $sortDirection ) { content { id title country indicatorsNames description dimensionsNames views } totalElements totalPages } }',
      variables: {
        page,
        country,
        lang: mapToBackendLanguageWithFallback(lang),
        name,
      },
    }),
  )
}

export async function getSolutionTypes() {
  return await axios<
    ServerResponse<{
      solutionTypes: Array<{ code: string; description: string }>
    }>
  >({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: 'query solutionTypes { solutionTypes { code description} }',
    }),
  })
}

export async function getSolutionById(id: number) {
  return await axios<ServerResponse<{ getSolutionById: Solution }>>({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'query getSolutionById($id: Long!) { getSolutionById(id: $id) { id title description contentRich contentText country showAuthor organization organizationName hub hubName stakeholder stakeholderName dimensionsNames dimensions stoplightTypes lang indicatorsNames indicatorsCodeNames contactInfo type resources { url type title description id } createdBy createdAt } }',
      variables: {
        id,
      },
    }),
  })
}

export async function deleteSolutionById(id: number) {
  return await axios({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query:
        'mutation deleteSolution($solution: StoplightSolutionModelInput) {deleteSolution(solution: $solution){successful} }',
      variables: {
        solution: {
          id,
        },
      },
    }),
  })
}

export async function getSolutionsAccessTypes() {
  interface SolutionAccess {
    code: string
    description: string
  }

  return await axios<ServerResponse<{ solutionsAccess: SolutionAccess[] }>>({
    method: 'post',
    url: '/graphql',
    data: JSON.stringify({
      query: 'query solutionsAccess { solutionsAccess { code description} }',
    }),
  })
}

export async function uploadSolutionsPicture(image: File) {
  return await axios<{ url: string }>({
    method: 'post',
    data: { upload: image },
    url: '/api/v1/solutions/pictures/upload',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
